import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import axios from "axios";
import { getCountry } from "../../utils/location_detector";

const signIn = createAsyncThunk("authSlice/SignIn", async ({ email, password }, thunkAPI) => {

    const url = baseUrl + "/user/login";
    try {
        const response = await axios.post(url, { "email": email, "password": password }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
        localStorage.setItem("token", response.data.user.token)
        return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
        console.log(err)
        const errorData = err.response.data
        return thunkAPI.rejectWithValue(errorData.message)

    }
},)


const googleSignIn = createAsyncThunk("authSlice/SignIn", async ({ credential }, thunkAPI) => {
    try {
        const response = await axios.post(`${baseUrl}/user/login`, { idToken: credential });
        localStorage.setItem("token", response.data.user.token);
        return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
        console.error("Google Login Failed:", error.response?.data || error.message);
        return thunkAPI.rejectWithValue(error.response?.data?.message || "An error occurred");
    }
});


const faceBookSignIn = createAsyncThunk("authSlice/SignIn", async ({ email }, thunkAPI) => {
    try {
        const response = await axios.post(`${baseUrl}/user/login`, { email: email, type: "facebook" });
        localStorage.setItem("token", response.data.user.token);
        return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
        console.error("Facebook Login Failed:", error.response?.data || error.message);
        return thunkAPI.rejectWithValue(error.response?.data?.message || "An error occurred");
    }
});

export const signUp = createAsyncThunk("authSlice/signUp", async ({ fullname, email, password }, thunkAPI) => {
    const url = baseUrl + "/user/register";
    try {
        const response = await axios.post(url, JSON.stringify({
            "fullname": fullname, "email": email, "country": getCountry(),
            "password": password
        }), {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})

export const googleSignUp = createAsyncThunk("authSlice/signUp", async ({ credential }, thunkAPI) => {
    const url = baseUrl + "/user/register";
    try {
        const response = await axios.post(url,  {
            idToken: credential, 
          }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})

export const faceBookSignUp = createAsyncThunk("authSlice/signUp", async ({ email, name, picture }, thunkAPI) => {
    const url = baseUrl + "/user/register";
    try {
        const response = await axios.post(url,  {
            email: email,
            fullname: name,
            picture: picture,
            type: "facebook"
          }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})

const AuthSlice = createSlice(
    {
        name: "Auth Slice",
        initialState: {
            initial: true,
            user:   null,
            mode: "login",
            loading: false,
            error: false,
            sucess: false,
            errorMsg: "An Error Occured! Please try again",

        },
        reducers: {
            logout(state) {
                state.initial = true
                state.mode = "login"
                state.loading = false
                state.error = false
                state.errorMsg = "An Error Occured! Please try again"
                state.user = null;
                localStorage.removeItem("token");
                window.location.reload();
            },

        },
        extraReducers: builder => {
            builder.addCase(signIn.pending, (state) => {
                state.loading = true
                state.initial = false;
            })
            builder.addCase(signIn.rejected, (state, action) => {
                state.loading = false;
                state.error = true
                state.errorMsg = action.payload


            })
            builder.addCase(signIn.fulfilled, (state, action) => {
                if (action.payload.status === "error") {
                    state.error = true;
                    state.loading = false;
                    state.errorMsg = action.payload.message
                } else {
                    state.user = action.payload.user
                    state.loading = false;
                    state.error = false;
                }
            })
            builder.addCase(signUp.pending, (state) => {
                state.mode = "signup";
                state.initial = true;
                state.loading = true;
                state.user = null;
                state.error = false;
            })
            builder.addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.sucess = true;
                state.error = false
            })
            builder.addCase(signUp.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorMsg = action.payload.message


            })
        },

    }
);
export const signInAction = signIn;
export const googleSignInAction = googleSignIn;
export const faceBookSignInAction = faceBookSignIn;
export const googleSignUpAction = googleSignUp;
export const faceBookSignUpAction = faceBookSignUp;
export const AuthActions = AuthSlice.actions

export default AuthSlice.reducer