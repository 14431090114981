import React, { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { RiArrowDropDownLine } from "react-icons/ri"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {AuthActions} from "../../redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";




export default function AccountToggle() {
    const authState = useSelector(state => state.protection);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
      const dispatchFN = useDispatch();
  const navigate = useNavigate();
  const path = window.location.pathname;

  // Extract the first segment of the path
  const firstSegment = path.split("/")[1]; // The first part after the slash


    function shortenWords(word, shorten = 5) {
        const lenght = word?.length
        if (!word || lenght < shorten) {
            return word
        }
        return word.substring(0, shorten) + "..."
    }
    const options = [
        {
            channelName: "Profile",//`${shortenWords(authState.user.fullname)} (Me)`,
            _id: 0,
        },
        ...authState.user.channelAccess,
        { channelName: "Log Out", _id: -1 }
    ];
  
    const handleMenuItemClick = (event, index, option) => {
        if (option.channelName === "Log Out") {
            dispatchFN(AuthActions.logout());
        }else  if (option.channelName === "Profile") {
            navigate(`/${firstSegment}/dashboard`)
        }else{
            setSelectedIndex(index);
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
        <ButtonGroup 
        variant="text" 
        ref={anchorRef} 
        aria-label="split button"
        sx={{
            "& .MuiButton-root": {
                color: "black", // Set text color
                fontSize: "13px", // Adjust font size
            },
        }}
    >
        <Button  className="no-hover" style={{border: 0, textTransform: "capitalize"}}>
            {authState.user.fullname?.split(" ")[0]}
        </Button>
        <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}

            className="no-hover"
            sx={{
                color: "black", // Black color for the dropdown arrow
                fontSize: "13px",
                border: 0,
            }}
        >
            <RiArrowDropDownLine />
        </Button>
    </ButtonGroup>
    
    <Popper
        sx={{
            zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
    >
        {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList 
                            id="split-button-menu" 
                            className="text-strong-black" 
                            autoFocusItem
                            sx={{
                                "& .MuiMenuItem-root": {
                                    fontSize: "13px", // Adjust font size of menu items
                                    color: "black", // Set text color
                                },
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option._id}
                                    // disabled={index === authState.accountSelect}
                                    // selected={index === authState.accountSelect}
                                    onClick={(event) => handleMenuItemClick(event, index, option)}
                                >
                                    {option.channelName}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>
    
        </React.Fragment>
    );
}
