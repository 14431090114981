import React, { useEffect, useRef, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUp as signUpAction, googleSignUpAction, faceBookSignUpAction } from "../../redux/reducers/authSlice"
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from '@mui/material/Alert';
import { GoogleLogin } from '@react-oauth/google';
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";


const SignUp = () => {
  const dispatch = useDispatch();
  const fullNameRef = useRef("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const countryRef = useRef("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { loading, error, sucess, mode, errorMsg } = useSelector(state => state.auth);
  const dispatchFN = useDispatch();

  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false)
      if (sucess) { navigate("/auth/login", { replace: true }) }
    }, 3000);
  }, [sucess])


  const signUpFN = () => {

    dispatchFN(signUpAction({ fullname: fullNameRef.current.value, email: emailRef.current.value, password: passwordRef.current.value }))

  }

  const handleGoogleSignUp = async (credentialResponse) => {
    dispatchFN(googleSignUpAction(credentialResponse));
  };
  const responseFacebook = (response) => {
    if (response?.email !== undefined || response?.email !== null) {
      dispatchFN(faceBookSignUpAction({ email: response?.email, name: response?.name, picture: response?.picture }));
    } else {
      alert("Facebook authentication not active at the moment")
    }
  };
  const submitForm = (event) => {
    event.preventDefault();
    signUpFN()
  }
  const handleClose = () => {
    setOpen(false);
  }

  return (

    <>

      {sucess && <Alert onClose={handleClose} severity={error == true ? "error" : "success"} sx={{ width: '100%' }}>
        {error === true ? errorMsg : "Sign Up Sucessful, Proceed to login"}
      </Alert>}
      <h1 className="font-bold text-3xl lg:text-4xl">Sign up</h1>

      <form onSubmit={submitForm} className="mt-12 w-full max-w-lg mx-auto px-5">
        <div className="relative w-full mb-2 py-3">
          <input
            id="fullname"
            name="fullname"
            type="text"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Full Name"
            ref={fullNameRef}
          />
          <label htmlFor="fullname" className="sr-only">
            Full Name
          </label>
        </div>
        <div className="relative w-full mb-2 py-3">
          <input
            id="email"
            name="email"
            type="text"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Email"
            required
            ref={emailRef}
          />
          <label htmlFor="email" className="sr-only">
            Email
          </label>
        </div>
        <div className="relative w-full mb-2 py-3">
          <input
            id="password"
            name="password"
            type="password"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Password"
            required
            ref={passwordRef}
          />
          <label htmlFor="password" className="sr-only">
            Password
          </label>
        </div>
        {/* <div className="relative w-full mb-12 py-3">
          <select
            className="form-select form-select-lg !shadow-none bg-clip-padding bg-no-repeat appearance-none px-3 py-2 w-full h-14 border border-gray rounded-md transition ease-in-out text-gray bg-white cursor-pointer focus:text-dark-gray focus:border-strong-red focus:outline-none"
            aria-label="Default country select"
            name="coutries"
            ref={countryRef}
          >
            <option value="all">Country</option>
            {countries.length > 0 &&
              <>
                {countries.map(country => {

                  return <option key={country.name} value={country.alpha2Code}>{country.name}</option>
                })}
              </>
            }
          </select>
        </div> */}
        {loading && <LoadingButton loading variant="outlined" className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all">
          Submit
        </LoadingButton>}
        {!loading && <button
          type="submit"
          className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
        >
          Proceed
        </button>}
      </form>

      <div className="mt-6 flex flex-col items-center w-full max-w-lg">
        <h2 className="">Or</h2>
        <div className="mt-6 flex gap-x-4 flex-row w-full px-5 center">
          <FacebookLogin
          appId="1070173991521454"
          autoLoad={false}
          callback={responseFacebook}
          fields="name,email,picture"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              type="button"
              className="kep-login-facebook metro"
            >
              <i className="fa fa-facebook"></i> Sign up with Facebook
            </button>
          )}
        />
          <GoogleLogin
            className="sign"
            onSuccess={credentialResponse => {
              handleGoogleSignUp(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        <div className="mt-6">
          Already have an account?{" "}
          <Link to="/auth/login" className="border-b border-strong-red font-bold">
            Sign in
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignUp;
