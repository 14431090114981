import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from "react-redux";
import { fecthForumList as forumList } from "../../../../redux/reducers/forumListSlice";
const NoticesHome = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(forumList())
  }, [])
  const forumState = useSelector((state) => state.forumList)

  return (
    <>
      <div className="min-h-screen ">
        {forumState.loading === true ? <CircularProgress /> : forumState.forums.map((forum, index) => {
          const date = new Date(forum.createDate);

          return <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6" key={index}>
            <i className="fas fa-star text-strong-red text-2xl lg:text-3xl"></i>
            <div className="flex-1 ml-3 lg:ml-7">
              <Link
                to={forum.forumTopic.toLocaleLowerCase()}
                className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
              >
                {forum.forumTopic}
              </Link>
              <p className="text-lg lg:text-xl mb-4">
                {forum.forumTheme}
              </p>
              <hr className="absolute inset-x-0 text-gray" />
              <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
                <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
                <p className="">
                  <span className="font-bold">Last Post {""}</span>
                  by Raja Muhammad on {date.getDay() + "-" + date.getMonth() + "-" + date.getFullYear()}
                </p>
              </div>
            </div>
          </div>
        })}

        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-strong-red text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="faq"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              FAQ
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New Content & Community Announcement
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-gray text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="update"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              Update
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New additions to Maisha Digital
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-gray text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="update"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              Update
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New additions to Maisha Digital
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-gray text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="update"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              Update
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New additions to Maisha Digital
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-gray text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="update"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              Update
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New additions to Maisha Digital
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full border border-gray rounded-md flex p-4 lg:p-7 relative mb-6">
          <i className="fas fa-star text-gray text-2xl lg:text-3xl"></i>
          <div className="flex-1 ml-3 lg:ml-7">
            <Link
              to="update"
              className="font-bold text-lg lg:text-xl mb-4 hover:text-gray transition-all"
            >
              Update
            </Link>
            <p className="text-lg lg:text-xl mb-4">
              New additions to Maisha Digital
            </p>
            <hr className="absolute inset-x-0 text-gray" />
            <div className="flex flex-col lg:flex-row lg:items-center justify-between pt-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post {""}</span>
                by yaw Djin on 09-14-2022
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="pagination flex items-center justify-center my-6">
        <span className="font-bold">Page</span>
        <nav
          className="relative flex items-center font-bold"
          aria-label="pagination"
        >
          <Link
            to=""
            className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              x-description="Heroicon name: solid/chevron-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
          <div className="flex">
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              1
            </Link>
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              2
            </Link>
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              3
            </Link>
          </div>
          <Link
            to=""
            className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              x-description="Heroicon name: solid/chevron-right"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </nav>
      </div>
    </>
  );
};

export default NoticesHome;
