import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInAction, googleSignInAction, faceBookSignInAction } from "../../redux/reducers/authSlice";
import Alert from '@mui/material/Alert';
import LoadingButton from "@mui/lab/LoadingButton";
import { GoogleLogin } from '@react-oauth/google';
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { baseUrl } from "../../config";
import Flash from "../../components/FlashMessage/FlashMessage";
import { getUser } from "../../redux/reducers/protectionSlice";
import axios from "axios";

const SignIn = () => {
  const emailRef = useRef("");
  const [open, setOpen] = useState(false);
  const passwordRef = useRef("");
  const dispatchFN = useDispatch();
  const state = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (state.user !== null || !state.loading) {
  //     setOpen(true);
  //   }
  //   setTimeout(() => {
  //     setOpen(false)
  //     dispatchFN(getUser())
  //     if (state.user !== null || state.user !== undefined) { navigate("/", { replace: true }) }
  //   }, 3000);
  // }, [state]);


  useEffect(() => {
    if (state.user) {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
            dispatchFN(getUser());
            const section = window.localStorage.getItem("section");
            navigate(section, { replace: true });
        }, 3000);
    }
}, [state.user]);


  const handleGoogleLogin = async (credentialResponse) => {
    dispatchFN(googleSignInAction(credentialResponse));
  };

  const loginFN = () => {
    dispatchFN(signInAction({ email: emailRef.current.value, password: passwordRef.current.value }))
  }
  const submitForm = (event) => {
    event.preventDefault();
    loginFN();
  }
  const handleClose = () => {
    setOpen(false);
  }

  const responseFacebook = (response) => {
    if(response?.email !== undefined || response?.email !== null){
      dispatchFN(faceBookSignInAction({email: response?.email}));
    }else{
      alert("Facebook authentication not active at the moment")
    }
  };

  return (
    <>
      {(open && !state.initial) && <Alert onClose={handleClose} severity={state.error && state.user == null ? "error" : "success"} sx={{ width: '100%' }}>
        {state.error && state.user == null ? state.errorMsg : ""}
      </Alert>}
      <h1 className="font-bold text-3xl lg:text-4xl">Sign in</h1>

      <form onSubmit={submitForm} className="mt-8 w-full max-w-lg mx-auto px-5">
        <div className="relative w-full  mb-2 py-3">
          <input
            id="email"
            name="email"
            type="email"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Email"
            required
            ref={emailRef}
          />
          <label htmlFor="email" className="sr-only">
            Email
          </label>
        </div>
        <div className="relative w-full mb-2 py-3">
          <input
            id="password"
            name="password"
            type="password"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Password"
            ref={passwordRef}
            required
          />
          <label htmlFor="password" className="sr-only">
            Password
          </label>
        </div>
        {state.loading && <LoadingButton loading variant="outlined" className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all">
          Submit
        </LoadingButton>}
        {!state.loading && <button
          type="submit"

          className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
        >
          Proceed
        </button>}
      </form>

      <div className="mt-3 flex flex-col items-center w-full max-w-lg">
        <h2 className="">Or</h2>
        <div className="mt-3 flex flex-row w-full px-5 align-items-center justify-between">
             <FacebookLogin
             appId="1070173991521454"
             autoLoad={false}
             callback={responseFacebook}
             fields="name,email,picture"
             render={(renderProps) => (
               <button
                 onClick={renderProps.onClick}
                 type="button"
                 className="kep-login-facebook metro"
               >
                 <i className="fa fa-facebook"></i> Log in with Facebook
               </button>
             )}
           />
          <GoogleLogin
            className="sign"
            onSuccess={credentialResponse => {
              handleGoogleLogin(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        <div className="mt-3">
          Don't have an account?{" "}
          <Link to="/auth/signup" className="font-bold">
            Sign up
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignIn;
