import React from "react";
import { motion } from "framer-motion";
import img1 from "../../assets/hero/hero-img-1.gif";
import img2 from "../../assets/hero/hero-img-2.svg";
import img3 from "../../assets/hero/hero-img-3.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const Hero = () => {
  // const [active, setactive] = useState(true);
  const { loading, error, carousel } = useSelector(state => state.carousel)
  const path = window.location.pathname;

  // Extract the first segment of the path
  const firstSegment = path.split("/")[1]; // The first part after the slash


    const scrollToSection = () => {
      const section = document.getElementById("target-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    };

  return (
    <section className="min-h-[40vh]">
      <h1 className="sr-only">Maisha</h1>
      <div >
        <Carousel autoPlay pagination showStatus={false} showThumbs={false} className="object-cover">
          <Link to={`/${firstSegment}/wiki`} className="carousel-item active relative float-left w-full mb-16 h-[400px] ">
            <img
              src={img1}
              className="blocklg:w-full m-[5px] object-contain h-[300px]"
              alt="girl walking towards a rabbit"
            />
            <div className="block absolute  inset-x-0 text-center max-w-xl lg:max-w-2xl mx-auto">
              <p className="mx-3 font-caveat">
                “Not everyone who chased the zebra caught it, but he who caught,
                chased it”{" "}
                <span className="text-strong-red">- African Proverb</span>
              </p>
            </div>
          </Link>

          {/* <!-- Single item --> */}
          <Link to={`/${firstSegment}/dashboard`} className="carousel-item relative float-left w-full mb-16 h-[400px] ">
            <img
              src={img2}
              className="block w-full lg:w-8/12 m-[5px] object-contain h-[300px]"
              alt="maisha logo"
            />
          </Link>
          {/* <!-- Single item --> */}
          <Link to={`/${firstSegment}/crowdfunding`} className="carousel-item relative float-left w-full mb-16 h-[400px] ">
            <img
              src={img3}
              className="block w-full lg:w-8/12 m-[5px] object-contain h-[300px]"
              alt="say not to rape culture"
            />
            <div className="block absolute -mb-2 inset-x-0 text-center max-w-xl lg:max-w-2xl mx-auto">
              <p className="mx-3">
                2 in 5 women in Ghana admit to their first sexual encounter
                being non cosentual. This is unacceptable Support us to bring
                public awareness to to this reprehensible act.{" "}
              {/*  <span className="text-strong-red font-bold">Find out more</span> */}
              </p>
            </div>
          </Link>

        </Carousel>
        <div className="flex flex-col items-center">
        <small>Scroll for more</small>
        <motion.div
          className="cursor-pointer text-black"
          onClick={scrollToSection}
          initial={{ y: 0 }}
          animate={{ y: [0, 10, 0] }} // Creates a floating up and down effect
          transition={{
            duration: 1.9,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v18m6-6-6 6-6-6"
            />
          </svg>
        </motion.div>
      </div>
      </div>
   
    </section>
  );
};

export default Hero;
